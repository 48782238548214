import { render, staticRenderFns } from "./Legend.vue?vue&type=template&id=18c26bc9&scoped=true"
import script from "./Legend.vue?vue&type=script&lang=js"
export * from "./Legend.vue?vue&type=script&lang=js"
import style0 from "./Legend.vue?vue&type=style&index=0&id=18c26bc9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c26bc9",
  null
  
)

export default component.exports